import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from './reducers';
import api from '../Middleware/api';
import failureToastr from '../Middleware/failureToastr';

export default (history: any) => {
  let appliedMiddleware = applyMiddleware(routerMiddleware(history), thunk, api, failureToastr);

  const useDevTools = true;

  if (useDevTools) {
    appliedMiddleware = composeWithDevTools(appliedMiddleware);
  }

  return createStore(
    createRootReducer(history),
    appliedMiddleware
  );
};
