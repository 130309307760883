import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PickupPage from '../Pages/Pickup/PickupPage';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={PickupPage} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
