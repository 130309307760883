import { Action } from '../../models';

export interface OrderState {
}

const initState: OrderState = {
};

export default (state = initState, action: Action): OrderState => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
