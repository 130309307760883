import { Action, UserModel } from '../../models';

export interface SiteState {
  user: UserModel | null;
}

const initState: SiteState = {
  user: null,
};

export default (state = initState, action: Action): SiteState => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
