import { CombinedState, combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import orderReducer, { OrderState } from './orders/reducer';
import siteReducer, { SiteState } from './site/reducer';

export interface RootState {
  router: RouterState;
  orders: OrderState;
  site: SiteState;
}

export default (history: any): Reducer<CombinedState<RootState>> => combineReducers<RootState>({
  router: connectRouter(history),
  orders: orderReducer,
  site: siteReducer,
});
