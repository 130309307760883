import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Routes from './Routes';

function App () {
  return (
    <>
      <Header />

      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main>
            <Routes />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
