import React, { useState } from 'react';

interface LocalProps {

}

const HTTP_PORT = 5080;
const HTTPS_PORT = 5443;
const currentPort = window.location.protocol === 'https:' ? HTTPS_PORT : HTTP_PORT;

const streams = [
  ``,
  `//c7c-media.scottrupprecht.com:${currentPort}/LiveApp/play.html?name=sdfsadf&autoplay=true`,
];

const PickupPage = () => {
  const [streamIds, setStreamIds] = useState([] as string[]);
  const [currentStream, setCurrentStream] = useState(null as string | null);

  return (
    <div className="row px-2">
      <div className="col-md-3">
        <div className="form-group">
          <label>Add Stream Id:</label>
          <input className="form-control mb-1" id="new-stream-input" placeholder="Stream Id..."/>

          <button className="btn btn-primary" onClick={() => {
            const streamId = (document.getElementById('new-stream-input') as HTMLInputElement)?.value || '';
            setCurrentStream(streamId);
            setStreamIds(streamIds.concat(streamId));
          }}>
            Add Stream
          </button>
        </div>
      </div>

      <div className="col-md-12 mb-2">
        {
          streamIds.map((streamId, i) => {
            const isSelected = currentStream === streamId;
            return (
              <button key={i} className={isSelected ? "btn btn-primary mr-1" : "btn btn-light mr-1"} onClick={() => setCurrentStream(streamId)}>Stream '{streamId}'</button>
            );
          })
        }
      </div>

      {currentStream && (
        <>
          <hr/>
          <div className="col-md-12">
            <div>
              <iframe width="560" height="315" src={`//c7c-media.scottrupprecht.com:${currentPort}/LiveApp/play.html?name=${currentStream || ''}&autoplay=true`} frameBorder="0" allowFullScreen/>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PickupPage;
