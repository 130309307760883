import _ from 'lodash';
import superagent from 'superagent';
import { Dispatch, MiddlewareAPI } from 'redux';

import { commonActions, unauthorized } from '../Modules/common/actions';
import { ApiAction, ApiPayload } from '../models';
import { RootState } from '../Modules/reducers';

const api = ({ dispatch, getState }: MiddlewareAPI<any, RootState>) => (next: Dispatch) => async (action: ApiAction) => {
  if (action.type !== commonActions.API) {
    return next(action);
  }

  const { site: { user } } = getState();

  if (!user) {
    return next(action);
  }

  const { beforeRequest, success, failure } = action.payload;

  if (_.isFunction(beforeRequest)) {
    dispatch(beforeRequest());
  }

  try {
    const request = await buildRequest(action.payload, user.token);

    const response = await request;
    const { body: result } = response;
    const successActionOrActions = success(result, response);
    if (_.isArray(successActionOrActions)) {
      _.each(successActionOrActions, (action) => dispatch(action));
    } else {
      dispatch(successActionOrActions);
    }
  } catch (err) {
    console.error(err);
    const { response } = err || {};

    if (response && response.unauthorized) {
      dispatch(unauthorized());
      return;
    }

    dispatch(failure({
      validationError: response && response.badRequest,
      message: _.get(response, 'body.message'),
      error: err,
      response: response,
    }));

    console.log(response);
    console.error(err);
  }
};

async function buildRequest (payload: ApiPayload, token: string) {
  const { getRequest } = payload;

  const request = getRequest(superagent);

  if (token) {
    request.set({ Authorization: `Bearer ${token}` });
  }

  return request;
}

export default api;
